import React, { useState } from "react";
import { DateTime } from "luxon";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

import Typography from "@ui-kit/Typography";
import { QUERIES } from "@constants/queries";
import { API, Models } from "@services/api";
import { PathParams } from "./types";
import { BookingRequest } from "@models/BookingRequest";
import { must } from "@utils/must";
import { formatPhone } from "@helpers/format-phone";
import { DownloadHistoryDialog } from "./ExportCsv";
import Button from "@ui-kit/Button";
import { Spacer } from "@ui-kit/Spacer";
import { formatDate } from "@utils/formatDate";
import { t } from "@i18n";
import { createCss } from "./styles";

export const BookingRequestsTable: React.FC = () => {
  const { facilityId } = useParams<PathParams>();
  const { data: requests, isLoading } = useQuery(
    QUERIES.USER_BOOKING_REQUEST,
    () =>
      API.booking.getList({
        facilityId: [facilityId],
        sort: Models.BookingRequestsSortableColumns.DatetimeFrom,
        order: "DESC",
      }),
    {
      refetchOnMount: "always",
    },
  );
  const [csvOpened, setCsvOpened] = useState(false);
  const css = createCss();

  if (isLoading) {
    return null;
  }

  if (!requests?.length) {
    return (
      <div>
        <Typography variant="inherit" bolded>
          No reservations as of yet
        </Typography>
      </div>
    );
  }

  const renderRow = (r: BookingRequest, idx: number) => {
    const timeFrom = DateTime.fromISO(r.bookingSlot.shift.dateTimeFrom, {
      zone: r.facilityCard.getTimezone(),
    });
    const timeTo = DateTime.fromISO(r.bookingSlot.shift.dateTimeTo, {
      zone: r.facilityCard.getTimezone(),
    });
    const createdAt = DateTime.fromISO(r.createdAt, {
      zone: r.facilityCard.getTimezone(),
    });

    return (
      <TableRow key={r.publicId}>
        <TableCell>{idx + 1}</TableCell>
        <TableCell css={css.cell}>{r.publicId}</TableCell>
        <TableCell css={css.cell}>{formatDate(createdAt, "date")}</TableCell>
        <TableCell css={css.cell}>{formatDate(timeFrom, "date")}</TableCell>
        <TableCell css={css.cell}>{formatDate(timeFrom, "weekday")}</TableCell>
        <TableCell css={css.cell}>{formatDate(timeFrom, "time")}</TableCell>
        <TableCell css={css.cell}>{formatDate(timeTo, "time")}</TableCell>
        <TableCell css={css.cell}>
          {r.isCancelled() ? formatDate(must(r.cancelledAt), "date") : null}
        </TableCell>
        <TableCell css={css.cell}>{r.facilityCard.name}</TableCell>
        <TableCell css={css.cell}>{r.facilityCard.address.city}</TableCell>
        <TableCell css={css.cell}>{r.facilityCard.address.state}</TableCell>
        <TableCell css={css.cell}>{r.facilityCard.address.zip}</TableCell>
        <TableCell css={css.cell}>{r.parent?.firstName}</TableCell>
        <TableCell css={css.cell}>{r.parent?.lastName}</TableCell>
        <TableCell css={css.cell}>
          {formatPhone(must(r.parent?.phone))}
        </TableCell>
        <TableCell css={css.cell}>{r.parent?.email}</TableCell>
        <TableCell css={css.cell}>{r.child.gender}</TableCell>
        <TableCell css={css.cell}>{r.child.firstName}</TableCell>
        <TableCell css={css.cell}>{r.child.lastName}</TableCell>
        <TableCell css={css.cell}>
          {formatDate(must(r.child.birthday), "date")}
        </TableCell>
        <TableCell css={css.cell}>
          {r.child.photo?.original ? (
            <a href={r.child.photo?.original} target="_blank" rel="noreferrer">
              view
            </a>
          ) : null}
        </TableCell>
        <TableCell css={css.cell}>
          {r.child.documents?.immunizationRecord.original ? (
            <a
              href={r.child.documents?.immunizationRecord.original}
              target="_blank"
              rel="noreferrer"
            >
              view
            </a>
          ) : null}
        </TableCell>
        <TableCell css={css.cell}>
          {r.child.documents?.medicalHistory.original ? (
            <a
              href={r.child.documents?.medicalHistory.original}
              target="_blank"
              rel="noreferrer"
            >
              view
            </a>
          ) : null}
        </TableCell>
        <TableCell css={css.cell}>{r.medicalInformation}</TableCell>
      </TableRow>
    );
  };

  return (
    <div>
      {csvOpened && (
        <DownloadHistoryDialog
          onClose={() => setCsvOpened(false)}
          facilityId={facilityId}
        />
      )}

      <div css={css.topBar}>
        <div>
          <Typography>Total reservations: {requests.length}</Typography>
        </div>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => setCsvOpened(true)}
        >
          {t("dashboard.exportButtonLabel")}
        </Button>
      </div>

      <Spacer size="sm" />

      <TableContainer component={Paper}>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell css={css.cell}>
                {t("dashboard.reservationsTable.columns.id")}
              </TableCell>
              <TableCell css={css.cell}>
                {t("dashboard.reservationsTable.columns.dateBooked")}
              </TableCell>
              <TableCell css={css.cell}>
                {t("dashboard.reservationsTable.columns.serviceDate")}
              </TableCell>
              <TableCell css={css.cell}>
                {t("dashboard.reservationsTable.columns.serviceDay")}
              </TableCell>
              <TableCell css={css.cell}>
                {t("dashboard.reservationsTable.columns.timeFromSchools")}
              </TableCell>
              <TableCell css={css.cell}>
                {t("dashboard.reservationsTable.columns.timeTo")}
              </TableCell>
              <TableCell css={css.cell}>
                {t("dashboard.reservationsTable.columns.dateCancelled")}
              </TableCell>
              <TableCell css={css.cell}>
                {t("dashboard.reservationsTable.columns.facilityName")}
              </TableCell>
              <TableCell css={css.cell}>
                {t("dashboard.reservationsTable.columns.facilityCity")}
              </TableCell>
              <TableCell css={css.cell}>
                {t("dashboard.reservationsTable.columns.facilityState")}
              </TableCell>
              <TableCell css={css.cell}>
                {t("dashboard.reservationsTable.columns.facilityZip")}
              </TableCell>
              <TableCell css={css.cell}>
                {t("dashboard.reservationsTable.columns.parentFirstName")}
              </TableCell>
              <TableCell css={css.cell}>
                {t("dashboard.reservationsTable.columns.parentLastName")}
              </TableCell>
              <TableCell css={css.cell}>
                {t("dashboard.reservationsTable.columns.parentPhone")}
              </TableCell>
              <TableCell css={css.cell}>
                {t("dashboard.reservationsTable.columns.parentEmail")}
              </TableCell>
              <TableCell css={css.cell}>
                {t("dashboard.reservationsTable.columns.childGender")}
              </TableCell>
              <TableCell css={css.cell}>
                {t("dashboard.reservationsTable.columns.childFirstName")}
              </TableCell>
              <TableCell css={css.cell}>
                {t("dashboard.reservationsTable.columns.childLastName")}
              </TableCell>
              <TableCell css={css.cell}>
                {t("dashboard.reservationsTable.columns.childBirthday")}
              </TableCell>
              <TableCell css={css.cell}>
                {t("dashboard.reservationsTable.columns.childPhoto")}
              </TableCell>
              <TableCell css={css.cell}>
                {t("dashboard.reservationsTable.columns.childImmunizations")}
              </TableCell>
              <TableCell css={css.cell}>
                {t("dashboard.reservationsTable.columns.childMedicalHistory")}
              </TableCell>
              <TableCell css={[css.cell, { minWidth: 240 }]}>
                {t(
                  "dashboard.reservationsTable.columns.childMedicalInformation",
                )}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{requests.map(renderRow)}</TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
