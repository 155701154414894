import React from "react";

import Typography from "@ui-kit/Typography";
import { BookingRequestsProps } from "./types";
import { BookingRequestsTable } from "./Table";
import { Spacer } from "@ui-kit/Spacer";

export const BookingRequests: React.FC<BookingRequestsProps> = ({
  facility,
}) => {
  return (
    <div>
      <Typography variant="h4" gutterBottom>
        {facility?.name}
      </Typography>
      <Typography>{facility?.getFullAddress()}</Typography>
      <Spacer size="md" />
      <BookingRequestsTable />
    </div>
  );
};
