import React from "react";
import track from "react-tracking";
import { useQuery } from "react-query";
import { Switch, Route, useParams, Redirect } from "react-router-dom";

import { BookingRequests } from "./BookingRequests";
import { createCss } from "./styles";
import { useUserStore } from "@store/UserStore";
import { must } from "@utils/must";
import { PathParams } from "./types";
import { QUERIES } from "@constants/queries";
import { API } from "@services/api";
import { isAdmin } from "@selectors";

const SchoolDashboard: React.FC = () => {
  const { facilityId } = useParams<PathParams>();
  const [{ user }] = useUserStore();
  const { data: facility } = useQuery(
    [QUERIES.GET_FACILITY_CARD, facilityId],
    () =>
      API.facilityCards.findOne({
        id: facilityId,
      }),
    {
      refetchOnMount: "always",
    },
  );
  const css = createCss();

  if (!facility) {
    return null;
  }

  if (must(user?.account.id) != facility.accountId && !isAdmin(user)) {
    return <Redirect to="/" />;
  }

  return (
    <div css={css.root}>
      <Switch>
        <Route
          path="/dashboard/facilities/:facilityId/reservations"
          render={() => <BookingRequests facility={facility} />}
        />
      </Switch>
    </div>
  );
};

export default track({
  page: "School Dashboard",
})(SchoolDashboard);
